import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { Icon, LoginSide } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  authResendOtp,
  authSendOtp,
  authVerifyOtp,
  contactUsApi,
  staffLogin,
  staffOperatorLogin,
} from "../../redux/authSession";
import { LogoFull } from "../../assets/icons";
import { FaCircleCheck } from "react-icons/fa6";
import { validateContactUs, validateStaffLogin } from "../../utils/validation";
import { clearContactUs } from "../../redux/authSession/authSession";
import PasswordInput from "../../component/forms/PasswordInput";

const StaffLogin = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staffLogin = useSelector((state) => state?.authSession?.staffLogin);


 

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated) {
      // navigate("/");
    } else {
      // navigate("/staffLogin");
      // navigate("/login");
    }
  }, [staffLogin]);

  
  const [formData, setFormData] = useState({
    staff_login_id: "",
    password: "",
  });
  const [error, setError] = useState({});





  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: null });
  };

  const handleStaffLogin = (e) => {
    e.preventDefault();
    // Log or perform actions with the form data
    let fromError = validateStaffLogin(formData);
    if (Object.keys(fromError).length > 0) {
      setError(fromError);
    } else {
     
      dispatch(staffOperatorLogin(formData)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          navigate("/");
        } else {
          console.error("Error Loging in", action.payload);
        }
      });
    }
  };

  return (
    <div>
      <Container className="gx-0" fluid>
        <Row className="gx-0">
          <Col
            lg="6"
            className="min-h-screen vstack bg-sand-1 d-none d-lg-flex"
            style={{ backgroundImage: `url(${LoginSide})` }}
          >
            <div className="m-auto">
              <Image src={LogoFull} className="" width={240} />
            </div>
          </Col>
        
            <Col lg="6" className="min-h-screen vstack p-10">
              <div className=""></div>
              <div className="my-auto" style={{ maxWidth: "328px" }}>
                <div className="hstack gap-3 mb-7">
                  <div className="h-12 w-12 rounded-circle border hstack justify-content-center">
                    <Image className="h-8 w-8" src={Icon} />
                  </div>
                  <h4>Hello! Welcome back</h4>
                </div>
                <Form>
                  <Form.Group className="mb-4">
                    <Form.Label className="text-dark text-base mb-4">
                      Enter staff ID
                    </Form.Label>
                    <Form.Control
                  type="text"
                  name="staff_login_id"
                  value={formData?.staff_login_id}
                  onChange={handleChange}
                /> <p className="text-danger">{error?.staff_login_id}</p>
                  </Form.Group>
                  <Form.Group className="mb-12">
                   
                    <PasswordInput
                    label="Password*"
                    name="password"
                    value={formData?.password}
                    onChange={handleChange}
                    error={error?.password}
                  />
                  </Form.Group>
                  <Button
                    variant="secondary"
                    className="w-full mb-6"
                    type="submit"
                    onClick={handleStaffLogin}
                  >
                   Login
                  </Button>
                  <p>
                    Don’t have an account yet?
                    <Link
                      className="text-secondary font-bold ms-1"
                      // onClick={handleShowFirstModal}
                      to={`https://play.google.com/store/apps/details?id=com.tapgro.seller&pcampaignid=web_share`}
                    >
                      Sign Up
                    </Link>
                  </p>
                </Form>
              </div>
             
              
              {/* <p>
                Don’t have an account yet? Let’s create it together
                <Link
                  className="text-secondary font-bold ms-1"
                  onClick={handleShowFirstModal}
                >
                  Contact Us
                </Link>
              </p> */}
            </Col>
         
        </Row>
      </Container>
    </div>
  );
};

export default StaffLogin;
