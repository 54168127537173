import React, { useEffect, useState } from "react";
import ReactFacebookLogin from "react-facebook-login";


const Facebookx = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '359819873037717',
        // appId: 'YOUR_APP_ID',
        cookie: true,
        xfbml: true,
        version: 'v20.0',   // Specify the latest Facebook SDK version here
        // version: 'v2.9',   // Specify the latest Facebook SDK version here
        status  : true,
      });
      window.FB.AppEvents.logPageView();
    };

    // Load the Facebook SDK
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);  // Ensure this is only called once on component mount

  const handleLogin = () => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        console.log('User logged in', response);
        // Use the accessToken to make API calls
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'ads_read, business_management' });
  };

  return (
    <div>
      <button onClick={handleLogin}>Login with Facebook</button>
    </div>
  );
};

const Facebook = () => {
  const responseFacebook = (response) => {
    console.log(response);
  }

  return (
    <ReactFacebookLogin
    appId="359819873037717"
    // appId="857717006387085"
    // appId="2149396555262169"
    // appId="1088597931155576"
    autoLoad={true}
    // autoLoad={false}
    fields="name,email,picture"
    // onClick={componentClicked}
    onClick={()=> console.log("clicked")
    }
    callback={responseFacebook} />
  );
};


export default Facebook;
