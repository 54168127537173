import React, { useEffect, useState } from "react";
import CommonLayout from "../../component/commonLayout";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { TbRefresh } from "react-icons/tb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertTimeToUTC, convertUTCToUserTimeZone, formatDateFromISO, getCurrencySymbolText, getCurrencySymbolTextforTd, ShowTotalEntriesCount } from "../../utils/commonFunctions";
import moment from "moment";
import PageLoader from "../../component/commonLayout/PageLoader";
import { RiDeleteBin5Line, RiPencilLine } from "react-icons/ri";
import {
  fetchAllExpenseCategories,
  fetchAllExpenses,
} from "../../redux/expense";
import EditExpense from "../../component/OffCanvases/EditExpense";
import DeleteExpenseModel from "../../component/Models/DeleteExpenseModel";
import PermissionGuard from "../../component/commonLayout/PermissionGuard";
import { hasEditPermission } from "../../utils/validation";

const ExpensesList = () => {
  const dispatch = useDispatch();
  const [showOffcanvas1, setShowOffcanvas1] = useState(false);
  const [selectedExpenseEntry, setSelectedExpenseEntry] = useState({});
  const [selectExpense, setSelectExpense] = useState({
    payment_mode: "",
    expense_type: "",
    search: "",
    start_date: "",
    end_date: "",
  });
  const allExpenses = useSelector((state) => state?.expense?.expenses);
  const fetchAllExpenseStatus = useSelector(
    (state) => state?.expense?.fetchAllExpenseStatus
  );
  const fetchAllExpenseCategoriesStatus = useSelector(
    (state) => state?.expense?.fetchAllExpenseCategoriesStatus
  );
  const [enquireTableData, setEnquireTableData] = useState({});
  const [expenseCategoriesList, setExpenseCategoriesList] = useState([]);
  const expenseCategories = useSelector(
    (state) => state?.expense?.expenseCategories
  );
  useEffect(() => {
    if (allExpenses?.data) {
      setEnquireTableData(allExpenses);
    }
  }, [allExpenses]);
  useEffect(() => {
    if (expenseCategories?.length) {
      setExpenseCategoriesList(expenseCategories);
    }
  }, [expenseCategories]);

  useEffect(() => {
    if (
      expenseCategories &&
      isObjectEmpty(expenseCategories) &&
      fetchAllExpenseCategoriesStatus === "idle"
    ) {
      dispatch(fetchAllExpenseCategories());
    }
  }, [expenseCategories, dispatch]);
  useEffect(() => {
    if (allExpenses && isObjectEmpty(allExpenses)) {
      dispatch(fetchAllExpenses({}));
    }
  }, [allExpenses, dispatch]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  // Function to toggle the visibility of Offcanvas 1 - Edit Expense
  const toggleOffcanvas1 = (item) => {
    setSelectedExpenseEntry(item);
    setShowOffcanvas1(!showOffcanvas1);
  };

  const [deleteModelShow, setDeleteModelShow] = useState(false);

  // Function to toggle the visibility of Model - Delete Expense
  const toggleDeleteModel = (item) => {
    setSelectedExpenseEntry(item);
    setDeleteModelShow(!showOffcanvas1);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setSelectExpense((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    dispatch(
      fetchAllExpenses({
        ...selectExpense,
        start_date: convertTimeToUTC(moment(selectExpense.start_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
        end_date: convertTimeToUTC(moment(selectExpense.end_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
        [name]: value,
      })
    );
  };

  const handleDateChange = (update) => {
    setDateRange(update);
    const [startDate, endDate] = update;
    const formattedStartDate = startDate
      ? moment(startDate).format("DD-MM-YYYY")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("DD-MM-YYYY")
      : null;
    setSelectExpense((prevState) => ({
      ...prevState,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    }));

    dispatch(
      fetchAllExpenses({
        ...selectExpense,
        // start_date: formattedStartDate,
        // end_date: formattedEndDate,
        start_date: convertTimeToUTC(moment(formattedStartDate).format("DD-MM-YYYY"), moment().format("hh:mm A")),
        end_date: convertTimeToUTC(moment(formattedEndDate).format("DD-MM-YYYY"), moment().format("hh:mm A")),
      })
    );
  };

  const refresh = () => {
    dispatch(
      fetchAllExpenses({
        search: selectExpense.search,
        // start_date: selectExpense.start_date,
        // end_date: selectExpense.end_date,
        start_date: convertTimeToUTC(moment(selectExpense.start_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
        end_date: convertTimeToUTC(moment(selectExpense.end_date).format("DD-MM-YYYY"), moment().format("hh:mm A")),
        payment_mode: selectExpense.payment_mode,
        expense_type: selectExpense.expense_type,
      })
    );
  };
  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );
  return (
    <PermissionGuard hasPermission={modulesPermissions?.expense?.is_create}>
      {" "}
      <div>
        {(fetchAllExpenseStatus === "loading" ||
          fetchAllExpenseCategoriesStatus === "loading") && <PageLoader />}
        <CommonLayout>
          <Stack direction="horizontal" className="gap-3 mb-6">
            <div className="flex-fill">
              <h4 className="mb-2">Expenses</h4>
              <p>You can add all your daily expenses here</p>
            </div>

            <div className="hstack gap-3 ">
              <div className="datepicker-staff">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  name="dateRange"
                  placeholderText="Select a date Range"
                  onChange={handleDateChange}
                  isClearable={true}
                  allowSameDay
                  peekNextMonth={false}
                  dateFormat={"MMM dd, YYYY"}
                  autoComplete="off"
                />
              </div>
              <Button
                variant=""
                size="md"
                className="rounded-1 border text-primary"
                onClick={refresh}
              >
                <TbRefresh /> Refresh
              </Button>
              {/* <Button
              variant=""
              size="md"
              className="rounded-1 border text-primary"
              onClick={refresh}
            >
              <TbRefresh /> clear
            </Button> */}
            </div>
          </Stack>

          <div className="mt-8">
            <Card className="rounded-bottom-0">
              <Card.Body>
                <Row className="align-items-center gy-4 justify-content-between">
                  <Col lg={4}>
                    <Stack direction="horizontal" className="gap-2">
                      <h6 className="text-nowrap">
                        {ShowTotalEntriesCount(
                          allExpenses?.data?.length,
                          "Entry",
                          "Entries"
                        )}{" "}
                      </h6>
                      <InputGroup className="form-group-1 w-sm-72 rounded-pill border me-auto">
                        <InputGroup.Text className="border-0 rounded-pill bg-transparent pe-1 ps-3">
                          <BiSearch className="text-lg" />
                        </InputGroup.Text>
                        <FormControl
                          className="border-0 rounded-pill ps-1"
                          type="text"
                          name="search"
                          placeholder="Search expense"
                          value={selectExpense?.search}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Stack direction="horizontal" className="gap-2">
                      <Form.Select
                        aria-label="Default select example "
                        size="sm"
                        name="expense_type"
                        value={selectExpense.expense_type}
                        onChange={handleInputChange}
                      >
                        {/* <option value="">All categories</option> */}
                        {/* {expenseCategoriesList?.map((category, categoryIndex)=><option value={category}>{category}</option>)} */}
                        <option value="">Select Expense type</option>
                        <option value="One-time">One Time</option>
                        <option value="Recurring">Recurring</option>
                      </Form.Select>
                      <Form.Select
                        aria-label="Default select example "
                        size="sm"
                        name="payment_mode"
                        value={selectExpense.payment_mode}
                        onChange={handleInputChange}
                      >
                        {/* <option value="">All</option> */}
                        <option value="">Select Payment Mode</option>
                        <option value="Cash">Cash</option>
                        <option value="Online">Online</option>
                      </Form.Select>
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="table-responsive border-start border-end mb-4">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Category</th>
                    <th>Receiver</th>
                    <th>Date</th>
                    <th>Payment mode</th>
                    <th>Remarks</th>
                    <th>Expense Type</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allExpenses?.data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.qty}</td>
                        <td>{item?.category}</td>
                        <td>{item?.receiver ? item?.receiver : "--"}</td>
                        {/* <td>
                          {item?.date
                            ? moment(item?.date).format("DD/MM/YYYY")
                            : "--"}
                        </td> */}
                        <td>
                          {item?.date
                            ? formatDateFromISO(convertUTCToUserTimeZone(item?.date))
                            : "--"}
                        </td>
                        <td>{item?.payment_mode}</td>
                        <td>{item?.remarks}</td>
                        <td>
                          {/* <span className="badge badge-lg  badge-dot border py-1 px-2 rounded-pill ">
                      {item?.enquiry_type === "Hot" ? (
                        <i className="enquiry-type-hot  me-1"></i>
                      ) : item?.enquiry_type === "warm" ? (
                        <i className="enquiry-type-warn  me-1"></i>
                      ) : (
                        <i className="enquiry-type-cold  me-1"></i>
                      )}

                      {item?.enquiry_type}
                    </span> */}
                          <Badge
                            bg=""
                            className={
                              item?.expense_type === "One-time"
                                ? "Booked"
                                : "Completed"
                            }
                          >
                            {item?.expense_type}
                          </Badge>
                        </td>
                        {/* <td>₹ {item?.amount}</td> */}
                        <td>{getCurrencySymbolTextforTd(item?.amount)}</td>

                        <td>
                          <div className="d-flex justify-content-center gap-2">
                            <Button
                              variant=""
                              onClick={() => toggleOffcanvas1(item)}
                              className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-2"
                              disabled={
                                !hasEditPermission(
                                  modulesPermissions?.expense?.is_edit
                                )
                              }
                            >
                              <RiPencilLine className="text-xs" />
                            </Button>
                            <Button
                              variant=""
                              onClick={() => {
                                toggleDeleteModel(item);
                              }}
                              disabled={
                                !hasEditPermission(
                                  modulesPermissions?.expense?.is_delete
                                )
                              }
                              className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-2"
                            >
                              <RiDeleteBin5Line />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Total Expenses:{" "}
                    </td>
                    <td colSpan={7}></td>
                    <td
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {getCurrencySymbolTextforTd(allExpenses?.totalExpense || 0)}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <EditExpense
            showOffcanvas1={showOffcanvas1}
            setShowOffcanvas1={setShowOffcanvas1}
            selectedExpenseEntry={selectedExpenseEntry}
            refresh={refresh}
          />
          <DeleteExpenseModel
            deleteModelShow={deleteModelShow}
            setDeleteModelShow={setDeleteModelShow}
            selectedExpenseEntry={selectedExpenseEntry}
            refresh={refresh}
          />
        </CommonLayout>
      </div>{" "}
    </PermissionGuard>
  );
};

export default ExpensesList;
