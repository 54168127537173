export const validateAddProduct = (productData) => {
  const {
    name,
    product_category,
    unit,
    volume,
    purchase_price,
    mrp,
    sales_price,
  } = productData;
  const errors = {};

  if (!name) {
    errors.name = "Please Enter Product Name";
  }
  if (!product_category) {
    errors.product_category = "Please Enter Product Category";
  }
  if (!unit) {
    errors.unit = "Please Select Unit";
  }
  if (!volume) {
    errors.volume = "Please Enter Volume";
  }
  if (!purchase_price) {
    errors.purchase_price = "Please Enter Purchase Price";
  }
  if (!mrp) {
    errors.mrp = "Please Enter MRP";
  }
  if (!sales_price) {
    errors.sales_price = "Please Enter Sales Price";
  }
  // if (!reward_points) {
  //   errors.reward_points = "Please Enter Reward Points";
  // }
  // if (!productData["barcode[]"].some((barcode) => barcode.trim() !== "")) {
  //   errors.barcode = "Please Enter Barcode";
  // }

  return errors;
};

export const validateEditProduct = (productData) => {
  const {
    name,
    product_category,
    unit,
    volume,
    purchase_price,
    mrp,
    sales_price,
  } = productData;
  const errors = {};

  if (!name) {
    errors.name = "Please Enter Product Name";
  }
  if (!product_category) {
    errors.product_category = "Please Enter Product Category";
  }
  if (!unit) {
    errors.unit = "Please Select Unit";
  }
  if (!volume) {
    errors.volume = "Please Enter Volume";
  }
  if (!purchase_price) {
    errors.purchase_price = "Please Enter Purchase Price";
  }
  if (!mrp) {
    errors.mrp = "Please Enter MRP";
  }
  if (!sales_price) {
    errors.sales_price = "Please Enter Sales Price";
  }
  // if (!reward_points) {
  //   errors.reward_points = "Please Enter Reward Points";
  // }

  return errors;
};

export const validateAddStock = (productData) => {
  const errors = {};
  // if (!productData["barcode[]"].some((barcode) => barcode.trim() !== "")) {
  //   errors.barcode = "Please Enter Barcode";
  // }

  return errors;
};

export const validateAddMember = (productData) => {
  const {
    name,
    price,
    validity,
    discount_services,
    discount_products,
    discount_packages,
    service_balance,
    category,
  } = productData;
  const errors = {};
  if (!name) {
    errors.name = "Please Enter Membership Name";
  }
  if (!price) {
    errors.price = "Please Enter Membership Price";
  }
  if (!validity) {
    errors.validity = "Please Enter Validity";
  }
  if (category === "discount") {
    if (!discount_services) {
      errors.discount_services = "Please Enter Discount Services";
    }
    if (!discount_products) {
      errors.discount_products = "Please Enter Discount Products";
    }
    if (!discount_packages) {
      errors.discount_packages = "Please Enter Discount Packages";
    }
  } else if (category === "balance") {
    if (!service_balance || service_balance <= 0) {
      errors.service_balance = "Please Enter Service Balance";
    }
  }

  return errors;
};

export const validateAddPackage = (productData) => {
  const { name, discount_price, services } = productData;
  const errors = {};
  if (!name) {
    errors.name = "Please Enter Package Name";
  }
  // if (!description) {
  //   errors.description = "Please Enter Package Description";
  // }
  if (!discount_price) {
    errors.discount_price = "Please Enter Discounted Price";
  }
  if (services.length < 1) {
    errors.services = "Please select atleast one Service";
  }

  return errors;
};
export const validateEditPackage = (productData) => {
  const { name, discount_price, services } = productData;
  const errors = {};
  if (!name) {
    errors.name = "Please Enter Package Name";
  }
  // if (!description) {
  //   errors.description = "Please Enter Package Description";
  // }
  if (!discount_price) {
    errors.discount_price = "Please Enter Discounted Price";
  }
  if (services.length < 1) {
    errors.services = "Please select atleast one Service";
  }

  return errors;
};

export const validateAddStaff = (productData) => {
  const {
    phone,
    staff_name,
    role,
    gender,
    product_level_commission,
    service_level_commission,
    amount,
    password,
    confirmPassword,
    isMakeStaff,
  } = productData;
  const errors = {};
  if (!phone) {
    errors.phoneNumber = "Please Enter phone number";
  }
  if (!staff_name) {
    errors.staffName = "Please Enter staff name";
  }
  if (!role) {
    errors.role = "Please Enter role";
  }
  if (!gender) {
    errors.gender = "Please Enter gender";
  }
  if (service_level_commission > 100) {
    errors.service_level_commission = "Service commission cannot exceed 100%";
  }
  if (product_level_commission > 100) {
    errors.product_level_commission = "Product commission cannot exceed 100%";
  }
  // Password and Confirm Password Validation
  if (isMakeStaff) {
    if (!password) {
      errors.password = "Please enter a password";
    } else if (password.length < 8) {
      // Example length requirement
      errors.password = "Password must be at least 8 characters";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
  }
  // if (!amount || amount < 0) {
  //   errors.amount = "Please Enter Salary Amount";
  // }

  return errors;
};

export const validateEditStaff = (productData) => {
  const { phone, staff_name, role, gender, amount } = productData;
  const errors = {};
  if (!phone) {
    errors.phoneNumber = "Please Enter phone number";
  }
  if (!staff_name) {
    errors.staffName = "Please Enter staff name";
  }
  if (!role) {
    errors.role = "Please Enter role";
  }
  if (!gender) {
    errors.gender = "Please Enter gender";
  }
  // if (!amount || amount < 0) {
  //   errors.amount = "Please Enter Salary Amount";
  // }
  // if (!service_level_commission) {
  //   errors.service_level_commission = "Please Enter product level commission";
  // }
  // if (!product_level_commission) {
  //   errors.product_level_commission = "Please Enter service level commission";
  // }

  return errors;
};

export const validateMonthalyAvailability = (formData) => {
  const { status, inTime, outTime } = formData;
  const errors = {};
  if (status !== "Absent") {
    if (!inTime) {
      errors.inTime = "Please Enter Punch-In time";
    }
    if (!outTime) {
      errors.outTime = "Please Enter Punch-Out time";
    }
  }

  return errors;
};

export const validateAddClient = (formData) => {
  const { phone, name, gender, sourceOfClient, pincode } = formData;

  const errors = {};

  if (!phone) {
    errors.phone = "Please enter phone number";
  }
  if (phone && phone.length < 10) {
    errors.phone = "Please enter 10 digit phone number";
  }
  if (!name) {
    errors.name = "Please enter client name";
  }
  if (!gender) {
    errors.gender = "Please select gender";
  }
  if (!sourceOfClient) {
    errors.sourceOfClient = "Please select source Of Client";
  }
  if (pincode && pincode.length < 6) {
    errors.pincode = "Please enter 6 digit pincode";
  }

  return errors;
};

export const validateBookAppointment = (formData) => {
  const {
    date,
    startDate,
    endDate,
    staff_appointed_id,
    frequency,
    client_id,
    sub_service_id,
    package_id,
  } = formData;

  const errors = {};

  if (!date) {
    errors.date = "Please enter Date";
  }
  if (!startDate) {
    errors.startDate = "Please enter StartDate name";
  }
  if (!endDate) {
    errors.endDate = "Please enter EndDate";
  }
  if (!staff_appointed_id) {
    errors.staff_appointed_id = "Please select Staff";
  }
  if (!frequency) {
    errors.frequency = "Please select frequency";
  }
  if (!client_id) {
    errors.client_id = "Please Add Client";
  }
  // if (!Array.isArray(sub_service_id) || sub_service_id.length === 0) {
  //   errors.sub_service_id = "Please Add Services";
  // }
  if (
    (!Array.isArray(sub_service_id) || sub_service_id.length === 0) &&
    (!Array.isArray(package_id) || package_id.length === 0)
  ) {
    errors.noItemSelected = "Please select at least one Service or Package.";
  }

  return errors;
};
export const validateEditBookAppointment = (formData) => {
  const {
    date,
    startDate,
    endDate,
    staff_appointed_id,
    frequency,
    client_id,
    sub_service_id,
    package_id,
  } = formData;

  const errors = {};

  if (!date) {
    errors.date = "Please enter Date";
  }
  if (!startDate) {
    errors.startDate = "Please enter StartDate name";
  }
  if (!endDate) {
    errors.endDate = "Please enter EndDate";
  }
  if (!staff_appointed_id) {
    errors.staff_appointed_id = "Please select Staff";
  }
  if (!frequency) {
    errors.frequency = "Please select frequency";
  }
  if (!client_id) {
    errors.client_id = "Please Add Client";
  }
  // if (!Array.isArray(sub_service_id) || sub_service_id.length === 0) {
  //   errors.sub_service_id = "Please Add Services";
  // }
  if (
    (!Array.isArray(sub_service_id) || sub_service_id.length === 0) &&
    (!Array.isArray(package_id) || package_id.length === 0)
  ) {
    errors.noItemSelected = "Please select at least one Service or Package.";
  }

  return errors;
};

export const validateRecordAddress = (formData) => {
  const { state, city, pincode, street_address } = formData;

  const errors = {};

  if (!state) {
    errors.state = "Please enter state";
  }
  if (!city) {
    errors.city = "Please enter city name";
  }
  if (!pincode) {
    errors.pincode = "Please enter pincode number";
  } else if (pincode.length < 6) {
    errors.pincode = "Please enter 6 digit pincode";
  }
  if (!street_address) {
    errors.street_address = "Please enter Street Address";
  }
  return errors;
};

export const validateAddEnquiry = (formData) => {
  const { number, enquiry_for, enquiry_type, source_of_enquiry } = formData;

  const errors = {};

  if (!number) {
    errors.number = "Please enter number";
  }
  if (!enquiry_for) {
    errors.enquiry_for = "Please enter enquiry for";
  }
  if (!enquiry_type) {
    errors.enquiry_type = "Please enter enquiry type";
  }
  if (!source_of_enquiry) {
    errors.source_of_enquiry = "Please enter source of enquiry";
  }
  return errors;
};

export const validateAddBookAppointment = (formData) => {
  const { name, number, date_to_followup, date_time_to_followup } = formData;

  const errors = {};

  if (!name) {
    errors.name = "Please enter name";
  }
  if (!number) {
    errors.number = "Please enter phone number";
  } else if (number.length !== 10) {
    errors.number = "Phone number must be 10 digits";
  }
  if (!date_to_followup) {
    errors.date_to_followup = "Please enter date";
  }
  if (!date_time_to_followup) {
    errors.date_time_to_followup = "Please enter time";
  }
  return errors;
};

export const validateContactUs = (formData) => {
  const { name, phone, location, business } = formData;

  const errors = {};
  if (!name) {
    errors.name = "Please enter name";
  }
  if (!phone) {
    errors.phone = "Please enter phone number";
  }
  if (!location) {
    errors.location = "Please enter location";
  }
  if (!business) {
    errors.business = "Please enter category";
  }
  return errors;
};

export const validateAddBalance = (formData) => {
  const { balance, transaction_mode } = formData;

  const errors = {};
  if (!balance) {
    errors.balance = "Please enter balance";
  }
  if (!transaction_mode) {
    errors.transaction_mode = "Please enter transaction mode";
  }
  return errors;
};

export const validateServicesTaken = (formData) => {
  const { durationInDays, reminder_channel } = formData;

  const errors = {};

  if (!durationInDays) {
    errors.durationInDays = "Please enter duration In Days";
  }
  if (!reminder_channel) {
    errors.reminder_channel = "Please enter channel";
  }
  return errors;
};

export const validateAddExpense = (formData) => {
  const { name, category, amount, date } = formData;

  const errors = {};

  if (!name) {
    errors.name = "Please enter name";
  }
  if (!category) {
    errors.category = "Please enter category";
  }
  if (!amount) {
    errors.amount = "Please enter amount";
  }
  if (!date) {
    errors.date = "Please Select date";
  }
  return errors;
};

export const validateEditExpense = (formData) => {
  const { name, category, amount, date } = formData;

  const errors = {};

  if (!name) {
    errors.name = "Please enter name";
  }
  if (!category) {
    errors.category = "Please enter category";
  }
  if (!amount) {
    errors.amount = "Please enter amount";
  }
  if (!date) {
    errors.date = "Please Select date";
  }
  return errors;
};

export const validateEditBranch = (formData) => {
  const { branch_name, address, pincode, city, state } = formData;

  const errors = {};

  if (!branch_name) {
    errors.branch_name = "Please enter branch name";
  }
  if (!address) {
    errors.address = "Please enter address";
  }
  if (!pincode) {
    errors.pincode = "Please enter pincode";
  }
  if (!city) {
    errors.city = "Please enter city";
  }
  if (!state) {
    errors.state = "Please enter state";
  }
  return errors;
};

export const validateChangePassword = (formData) => {
  const { password, confirmPassword } = formData;

  const errors = {};

  // Password and Confirm Password Validation
  if (!password) {
    errors.password = "Please enter a password";
  } else if (password.length < 8) {
    // Example length requirement
    errors.password = "Password must be at least 8 characters";
  }

  if (!confirmPassword) {
    errors.confirmPassword = "Please confirm your password";
  } else if (password !== confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

export const validateStaffLogin = (formData) => {
  const { staff_login_id, password } = formData;

  const errors = {};

  // Password and Confirm Password Validation
  if (!staff_login_id) {
    errors.staff_login_id = "Please enter a staff login id";
  }
  //  else if (password.length < 8) {
  //   // Example length requirement
  //   errors.password = "Password must be at least 8 characters";
  // }
  if (!password) {
    errors.password = "Please enter your password";
  }

  return errors;
};

export const hasEditPermission = (permission) => {
  const isOwner = JSON.parse(localStorage.getItem("isOwner"));

  // If the user is the owner, they automatically have permission
  if (isOwner) {
    return true;
  }

  // If the permission is still undefined (loading), disable the button
  if (permission === undefined) {
    return false;
  }

  // Otherwise, return the actual permission
  return permission;
};
