const PKRupeeIcon = () => (

  <svg
    // fill="#000000"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    // width="800px"
    // height="800px"
    // viewBox="0 0 73.934 73.934"
    // xml:space="preserve"

    viewBox="0 0 73.934 73.934"
    width="1em"  // Matches the size scaling of react-icons
    height="1em" // Matches the size scaling of react-icons
    fill="currentColor" // Matches the color of surrounding text/icons
  >
    <g>
      <path
        d="M45.271,26.369h7.381c1.401,0,2.537-1.139,2.537-2.539c0-1.402-1.136-2.537-2.537-2.537h-7.431
		c-0.478-3.353-1.94-6.252-4.267-8.303c-3.484-3.152-8.413-4.561-15.984-4.561L11.71,8.474c-0.408,0-0.736,0.332-0.736,0.735v12.083
		H2.537C1.136,21.292,0,22.427,0,23.829c0,1.4,1.136,2.539,2.537,2.539h8.437v36.726c0,0.407,0.328,0.738,0.738,0.738h6.907
		c0.409,0,0.736-0.331,0.736-0.738V40.642h11.159l8.221,22.703c0.105,0.296,0.381,0.488,0.692,0.488h7.147
		c0.244,0,0.469-0.118,0.608-0.316c0.136-0.197,0.167-0.453,0.084-0.679l-9.071-24.256C42.675,34.823,44.756,31.295,45.271,26.369z
		 M19.355,15.232l6.09-0.004c4.432,0.05,9.557,1.233,11.102,6.065H19.355V15.232z M25.688,33.924h-6.333v-7.555h17.49
		C35.97,31.015,31.79,33.924,25.688,33.924z M73.934,53.86c0,7.071-5.594,11.645-14.253,11.645c-3.877,0-7.681-0.93-10.435-2.551
		l-0.523-0.311c-0.154-0.089-0.223-0.271-0.167-0.441l2.091-6.367c0.037-0.11,0.125-0.199,0.234-0.24
		c0.109-0.037,0.234-0.021,0.331,0.043l0.823,0.537c1.793,1.173,4.895,2.354,7.791,2.354c3.559,0,5.684-1.526,5.684-4.088
		c0-2.434-1.461-3.791-5.786-5.39c-8.278-2.954-10.019-7.606-10.019-10.995c0-6.728,5.443-11.426,13.238-11.426
		c3.295,0,6.736,0.871,9.205,2.332l0.528,0.313c0.155,0.093,0.224,0.278,0.165,0.449l-2.105,6.124
		c-0.037,0.108-0.123,0.195-0.23,0.232c-0.109,0.039-0.229,0.024-0.328-0.037l-0.807-0.509c-0.881-0.56-3.289-1.855-6.571-1.855
		c-2.835,0-4.741,1.468-4.741,3.65c0,2.037,1.333,3.134,5.941,4.889C70.869,44.834,73.934,48.425,73.934,53.86z"
      />
    </g>
  </svg>
);

export default PKRupeeIcon;
